@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i,900|Montserrat:900);
:root {
  --primary-color: #d23669;
  --primary-color-light: rgb(255, 167, 196);
}

* {
  box-sizing: border-box;
}

html {
  color: rgba(0, 0, 0, 0.9);
  font: 100%/1.75 Morriweather, Georgia, serif;
}

body {
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-family: 'Merriweather','Georgia',serif;
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt", "kern";
          font-feature-settings: "kern", "liga", "clig", "calt", "kern";
  font-weight: 400;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

pre,
code {
  font-family: Consolas, Menlo, Monaco, source-code-pro, "Courier New", monospace;
  font-size: 0.85rem;
  line-height: 1.5;
}
pre {
  border-radius: 10px;
  overflow: auto;
  padding: 1em;
}

a {
  color: #d23669;
  color: var(--primary-color);
}
a:hover {
  text-decoration: none;
}

p, pre, ul, ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
}
.ArticleMeta_tags__2MTqK,
.ArticleMeta_tags__2MTqK li {
  display: inline;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ArticleMeta_tags__2MTqK li:not(:first-child)::before {
  content: ', ';
}
.ArticleSummary_ArticleSummary__3o2nW h2 {
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 0;
}

.ArticleSummary_ArticleSummary__3o2nW a {
  text-decoration: none;
}
.Bio_Bio__3GIX7 {
  display: flex;
  margin-bottom: 2rem;
}
  
.Bio_Bio__3GIX7 img {
  border-radius: 50%;
  flex-shrink: 0;
  height: 3.5rem;
  margin-bottom: 0;
  margin-right: 0.875rem;
  width: 3.5rem;
}
.Pagination_Pagination__2RDry {
  display: block;
  margin: 4.375rem 0 7rem;
}
  
.Pagination_Pagination__2RDry .Pagination_previous__cWHeF {
  margin-right: 0.5rem;
}
.Pagination_Pagination__2RDry .Pagination_next__3lJf4 {
  margin-left: 0.5rem;
}
.BlogIndexPage_title__MPXr- {
  font-family: Montserrat, sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  text-rendering: optimizeLegibility;
}
.BlogIndexPage_title__MPXr- a {
  color: black;
  text-decoration: none;
}

.BlogIndexPage_robot__1WOz6 {
  width: 100%
}

.BlogIndexPage_articlesList__NCL4A,
.BlogIndexPage_articlesList__NCL4A > li {
  list-style: none;
  padding: 0;
  margin: 0 0 2.625rem;;
}

.BlogIndexPage_footer__3JPD_ {
  margin-top: 7rem;
  margin-bottom: 0.5rem;
}

.LoadingIndicator_LoadingIndicator__1wPt1 {
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
  background-color: var(--primary-color-light);
  background-size: 35px 35px;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
  -webkit-transition: opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  transition: opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  transition: transform ease-in 300ms, opacity ease-in 300ms;
  transition: transform ease-in 300ms, opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  -webkit-transition-delay: 0;
          transition-delay: 0;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  opacity: 0;
}

.LoadingIndicator_LoadingIndicator__1wPt1.LoadingIndicator_active__GxYFT {
  opacity: 1;
  -webkit-transition-delay: 333ms;
          transition-delay: 333ms;
  -webkit-animation: LoadingIndicator_LoadingIndicator-animation__1n8GN 10s ease-out;
          animation: LoadingIndicator_LoadingIndicator-animation__1n8GN 10s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes LoadingIndicator_LoadingIndicator-animation__1n8GN {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  10% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
  50% {
    -webkit-transform: scaleX(0.7);
            transform: scaleX(0.7);
  }
  90% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes LoadingIndicator_LoadingIndicator-animation__1n8GN {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  10% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
  50% {
    -webkit-transform: scaleX(0.7);
            transform: scaleX(0.7);
  }
  90% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
.BlogLayout_container__wEa1_ {
  margin: 0 auto;
  max-width: 42rem;
  padding: 2.625rem 1.3125rem;
}

.BlogLayout_title__2ohVR {
  font-family: Montserrat, sans-serif;
  font-size: 1.44rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
}
.BlogLayout_title__2ohVR > a {
  color: var(--primary-color-light);
  text-decoration: none;
}

.BlogPostLayout_title__2Hoa6 {
  font-family: Montserrat, sans-serif;
  font-weight: 900;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
}
.BlogPostLayout_title__2Hoa6 > a {
  text-decoration: none;
}

.BlogPostLayout_header__2OQva {
  margin-bottom: 2.4rem;  
}
.BlogPostLayout_header__2OQva .BlogPostLayout_title__2Hoa6 {
  font-size: 2.5rem;
  margin-top: 1.7rem;
  margin-bottom: 0;
}
.BlogPostLayout_header__2OQva .BlogPostLayout_title__2Hoa6 > a {
  color: black;
}

.BlogPostLayout_footer__7JvJj {
  margin-top: 7rem;
}
.BlogPostLayout_footer__7JvJj .BlogPostLayout_title__2Hoa6 {
  font-size: 1.44rem;
  margin-top: 0;
  margin-bottom: 2.4rem;
}
.BlogPostLayout_footer__7JvJj .BlogPostLayout_title__2Hoa6 > a {
  color: var(--primary-color-light);
}

.BlogPostLayout_bio__2R_xV {
  margin-bottom: 7rem;
}

.BlogPostLayout_links__1-oQr {
  display: flex;
  justify-content: space-between;
}
.BlogPostLayout_links__1-oQr .BlogPostLayout_next__rQnzG {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
}

.BlogPostLayout_content__Xan8k hr {
  background: hsla(0,0%,0%,0.2);
  border: none;
  height: 1px;
}

.BlogPostLayout_content__Xan8k h2,
.BlogPostLayout_content__Xan8k h3,
.BlogPostLayout_content__Xan8k h4,
.BlogPostLayout_content__Xan8k h5,
.BlogPostLayout_content__Xan8k h6 {
  font-weight: 900;
  line-height: 1.1;
  margin-top: 3.5rem;
  text-rendering: optimizeLegibility;
}
  
/**
* Based on copypasta from Remy Bach and Sarah Drasner
*/
.BlogPostLayout_content__Xan8k code,
.BlogPostLayout_content__Xan8k pre {
  color: white;
  font-family: Consolas,Menlo,Monaco,source-code-pro,Courier New,monospace;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
       tab-size: 4;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
  
/* Code blocks */
.BlogPostLayout_content__Xan8k pre {
  overflow: auto;
  padding: 1em;
}

.BlogPostLayout_content__Xan8k :not(pre) > code,
.BlogPostLayout_content__Xan8k pre {
  background: hsla(0,0%,0%,0.9);
}
  
.BlogPostLayout_content__Xan8k pre::-moz-selection,
.BlogPostLayout_content__Xan8k pre::-moz-selection {
  background: hsl(207, 4%, 16%);
}
  
.BlogPostLayout_content__Xan8k pre::-moz-selection,
.BlogPostLayout_content__Xan8k pre::selection {
  background: hsl(207, 4%, 16%);
}
  
/* Text Selection colour */
.BlogPostLayout_content__Xan8k pre::-moz-selection,
.BlogPostLayout_content__Xan8k pre ::-moz-selection {
  text-shadow: none;
  background: hsla(0, 0%, 100%, 0.15);
}

.BlogPostLayout_content__Xan8k pre::selection,
.BlogPostLayout_content__Xan8k pre ::selection {
  text-shadow: none;
  background: hsla(0, 0%, 100%, 0.15);
}

/* Inline code */
.BlogPostLayout_content__Xan8k :not(pre) > code {
  border-radius: .3em;
  background: rgba(255,229,100,0.2);
  color: #1a1a1a;
  padding: .15em .2em .05em;
  white-space: normal;
}
.BlogPostLayout_content__Xan8k a > code {
  color: var(--primary-color);
}

.BlogPostLayout_content__Xan8k .highlighted-line {
  background-color: hsla(207, 95%, 15%, 1);
  display: block;
  margin-right: -1em;
  margin-left: -1em;
  padding-right: 1em;
  padding-left: 0.75em;
  border-left: 0.25em solid #ffa7c4;
}

.BlogPostLayout_content__Xan8k .token.attr-name {
  color: rgb(173, 219, 103);
  font-style: italic;
}

.BlogPostLayout_content__Xan8k .token.comment {
  color: rgb(99, 119, 119);
}

.BlogPostLayout_content__Xan8k .token.string,
.BlogPostLayout_content__Xan8k .token.url {
  color: rgb(173, 219, 103);
}

.BlogPostLayout_content__Xan8k .token.variable {
  color: rgb(214, 222, 235);
}

.BlogPostLayout_content__Xan8k .token.number {
  color: rgb(247, 140, 108);
}

.BlogPostLayout_content__Xan8k .token.builtin,
.BlogPostLayout_content__Xan8k .token.char,
.BlogPostLayout_content__Xan8k .token.constant,
.BlogPostLayout_content__Xan8k .token.function {
  color: rgb(130, 170, 255);
}

.BlogPostLayout_content__Xan8k .token.punctuation {
  color: rgb(199, 146, 234);
}

.BlogPostLayout_content__Xan8k .token.selector,
.BlogPostLayout_content__Xan8k .token.doctype {
  color: rgb(199, 146, 234);
  font-style: 'italic';
}

.BlogPostLayout_content__Xan8k .token.class-name {
  color: rgb(255, 203, 139);
}

.BlogPostLayout_content__Xan8k .token.tag,
.BlogPostLayout_content__Xan8k .token.operator,
.BlogPostLayout_content__Xan8k .token.keyword {
  color: #ffa7c4;
}

.BlogPostLayout_content__Xan8k .token.boolean {
  color: rgb(255, 88, 116);
}

.BlogPostLayout_content__Xan8k .token.property {
  color: rgb(128, 203, 196);
}

.BlogPostLayout_content__Xan8k .token.namespace {
  color: rgb(178, 204, 214);
}
